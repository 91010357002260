<template>
  <div>
    <b-row>
      <b-col>
        <statistic-card
          :statistic="counters.debit | toCurrency"
          icon="ClockIcon"
          statistic-title="Toplam Borç"
          color="warning"
          prefix="₺"
        />
      </b-col>
      <b-col>
        <statistic-card
          :statistic="counters.payment | toCurrency"
          icon="CheckCircleIcon"
          statistic-title="Toplam Ödenen"
          color="success"
          prefix="₺"
        />
      </b-col>
      <b-col>
        <statistic-card
          :statistic="counters.amount | toCurrency"
          icon="BriefcaseIcon"
          statistic-title="Güncel Bakiye"
          color="info"
          prefix="₺"
        />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BCol, BRow,
} from 'bootstrap-vue'
import StatisticCard from '@/views/Reports/components/StatisticCard.vue'

export default {
  name: 'Statistic',
  components: {
    StatisticCard,
    BRow,
    BCol,
  },
  computed: {
    counters() {
      return this.$store.getters['currentAccounts/counters']
    },
  },
}
</script>
