<template>
  <div>
    <statistic />
    <b-card no-body>
      <b-card-header>
        <b-card-title>Cari Hesaplar</b-card-title>
        <b-button
          v-if="!userData.id_customers"
          to="/current-accounts/search-customer"
          variant="primary"
        >
          <feather-icon icon="PlusIcon" />
          Oluştur
        </b-button>
      </b-card-header>
      <template v-if="dataList.length > 0">
        <b-table
          responsive="sm"
          :fields="fields"
          :items="dataList"
          striped
          hover
        >
          <template #cell(customers)="data">
            <div v-if="data.item.cnumber">
              <small class="text-warning">{{ data.item.cnumber }}</small>
            </div>
            {{ data.item.customers }}
            <div v-if="data.item.content">
              <small class="text-primary">{{ data.item.content }}</small>
            </div>
          </template>
          <template #cell(amount)="data">
            <div v-if="data.item.amount > '0'">
              <span class="text-success font-weight-bold">{{ data.item.amount | toCurrency }} TL</span>
            </div>
            <div v-else-if="data.item.amount < '0'">
              <span class="text-danger font-weight-bold">{{ data.item.amount | toCurrency }} TL</span>
            </div>
            <div v-else>
              <span class="font-weight-bold">{{ data.item.amount | toCurrency }} TL</span>
            </div>
          </template>
          <template #cell(control)="data">
            <div
              class="text-right"
            >
              <b-button
                v-if="userData.id_customers"
                :to="$route.path + '/view/' + data.item.id"
                variant="primary"
                size="sm"
              >
                Görüntüle
              </b-button>
              <list-buttons
                v-else
                primary-text="Görüntüle"
                :primary-action="$route.path + '/view/' + data.item.id"
                :edit-action="$route.path + '/edit/' + data.item.id"
                :delete-action="removeData"
                :data-id="data.item.id"
              />
            </div>
          </template>
        </b-table>
        <b-card-footer>
          <b-pagination
            v-model="currentPage"
            :total-rows="dataCount"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-card-footer>
      </template>
      <template v-else>
        <b-alert
          variant="info"
          show
          class="ml-2 mr-2"
        >
          <div class="alert-body text-center">
            <div>
              <FeatherIcon
                icon="InfoIcon"
                size="16"
              />
            </div>
            <p
              v-if="userData.id_customers"
              class="lead"
            >
              Henüz cari hesabınız oluşturulmamış.
            </p>
            <p
              v-else
              class="lead"
            >
              Tanımladığınız cari hesabı bulunmuyor.
            </p>
          </div>
        </b-alert>
      </template>
    </b-card>
  </div>
</template>
<script>
import {
  BAlert,
  BCard, BCardFooter,
  BCardHeader,
  BCardTitle,
  BPagination,
  BTable,
  BButton,
} from 'bootstrap-vue'
import Statistic from '@/views/CurrentAccounts/Components/Statistic.vue'
import ListButtons from '@/layouts/components/common/ListDropdownButtons.vue'

export default {
  name: 'CurrentAccounts',
  components: {
    ListButtons,
    BCardFooter,
    BAlert,
    BPagination,
    BTable,
    BCard,
    BCardTitle,
    BCardHeader,
    Statistic,
    BButton,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      currentPage: 1,
      perPage: this.$store.state.app.perPage,
      dataQuery: {
        select: [
          'current_accounts.id as id',
          'current_accounts.cnumber as cnumber',
          'current_accounts.content as content',
          'customers.company as customers',
          'customer_users.name as customer_user',
          'current_accounts.created as created',
          'users.name as username',
        ],
        limit: 10,
        start: 0,
      },
      fields: [

        {
          key: 'customers',
          label: 'MÜŞTERİ',
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap',
        },
        {
          key: 'amount',
          label: 'GÜNCEL BAKİYE',
          thClass: 'width-100 text-right text-nowrap',
          tdClass: 'width-100 text-right text-nowrap',
        },
        {
          key: 'control',
          label: '',
          thClass: 'width-100 text-nowrap',
          tdClass: 'width-100 text-nowrap',
        },
      ],
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['currentAccounts/dataList']
    },
    dataCount() {
      return this.$store.getters['currentAccounts/dataCounts']
    },
  },
  watch: {
    currentPage(val) {
      const page = (val - 1) * this.$store.state.app.perPage
      this.pagination(page)
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    pagination(page) {
      this.dataQuery.start = page
      if (this.dataQuery.start === page) {
        this.getDataList()
      }
    },
    getDataList() {
      this.$store.dispatch('currentAccounts/getDataList', this.dataQuery)
    },
    removeData(id) {
      this.$swal({
        title: this.$store.state.app.removeTitle,
        text: this.$store.state.app.removeMessage,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$store.state.app.removeConfirm,
        cancelButtonText: this.$store.state.app.removeCancel,
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('currentAccounts/removeData', id)
            .then(response => {
              if (response.status) {
                this.$swal({
                  icon: 'success',
                  title: this.$store.state.app.removeResultTitle,
                  text: this.$store.state.app.removeResultMessage,
                  confirmButtonText: this.$store.state.app.removeResultClose,
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
                this.getDataList()
              }
            })
        }
      })
    },
  },
}
</script>
